<template>
  <div class="login">
    <div class="login__fix">
      <div class="login__boy">
        <img src="@/assets/image/authority/auth_hugeboy.png" alt="" draggable="false">
      </div>
      <div class="login__box">
        <img src="@/assets/image/authority/auth_scanbox.png" alt="box" draggable="false">
        <div class="login__scan">
          <img src="@/assets/image/authority/auth_logo.png" alt="logo" draggable="false">
          <div class="login__radio">
            <div :class="{'active': loginType === ELoginType.Scan}" @click="onLoginMethodChange(ELoginType.Scan)">扫码登录
            </div>
            <div :class="{'active': loginType === ELoginType.Form}" @click="onLoginMethodChange(ELoginType.Form)">账号登录
            </div>
            <div :class="{'active': loginType === ELoginType.Code}" @click="onLoginMethodChange(ELoginType.Code)">
              授权码登录
            </div>
          </div>

          <div class="login__input">
<!--            <div v-show="loginType === ELoginType.Scan" id="login__qrcode" key="1"></div>-->
            <div v-show="loginType === ELoginType.Scan" class="login__qrcode">
              <div class="title">
                <img style="width: 29px;height: 24px" src="../../../assets/image/WeworkLogoBule$57136f6f.png" alt="">
                企业微信登录
              </div>
              <qrcode-vue class="login_qrcode_content" :value="queryLoginCodeList.url" :size="200" level="H" v-if="queryLoginCodeList" />
              <div class="login_qrcode_text" v-if="dataContainer.status === 2">二维码已扫描 <span style="color: #38adff;
    cursor: pointer;" @click="queryLoginCode">刷新</span> </div>
              <div class="login_qrcode_text" v-else>请使用{{queryLoginCodeList.channelSwitch === 'WX' ? '企业微信' : '钉钉'}}扫描二维码登录 <span style="color: #38adff;
    cursor: pointer;" @click="queryLoginCode">刷新</span> </div>
              <div class="login_qrcode_refresh" v-show="qrCodeStatus">
                您的二维码已失效，
                <br/>
                请点击下方刷新按钮
              </div>
            </div>
            <div v-show="loginType === ELoginType.Form" id="login__form" key="2">

              <!--                账号登录-->
              <a-form
                  :model="form"
                  @finish="onFinish(1)"
                  @finishFailed="onFinishFailed"
                  style="margin-top: 40px"
              >
                <a-form-item>
                  <a-input v-model:value="form.userName" placeholder="请输入用户账号/手机号" style="width: 400px;" size="large">
                    <template #prefix>
                      <UserOutlined style="color: rgba(0, 0, 0, 0.25)"/>
                    </template>
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-input v-model:value="form.password" type="password" placeholder="请输入密码" style="width: 400px;"
                           size="large">
                    <template #prefix>
                      <LockOutlined style="color: rgba(0, 0, 0, 0.25)"/>
                    </template>
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-button
                      type="primary"
                      html-type="submit"
                      :disabled="(!form.userName )|| (!form.password)"
                      size="large"
                      style="width: 400px;"
                  >
                    登录
                  </a-button>
                </a-form-item>
              </a-form>


            </div>

            <div v-show="loginType === ELoginType.Code" id="login__code" key="3">
              <a-form
                  :model="form2"
                  @finish="onFinish(2)"
                  @finishFailed="onFinishFailed"
                  style="margin-top: 40px"
              >
                <a-form-item>
                  <a-input v-model:value="form2.keyword" placeholder="请输入授权码" style="width: 400px;" size="large">
                    <template #prefix>
                      <SecurityScanOutlined style="color: rgba(0, 0, 0, 0.25)"/>
                    </template>
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-button
                      type="primary"
                      html-type="submit"
                      :disabled="!form2.keyword"
                      size="large"
                      style="width: 400px;"
                  >
                    登录
                  </a-button>
                </a-form-item>
              </a-form>
            </div>
          </div>
          <div style="margin-top: 20px">
            <a-button type="text" style="color: #FF9900" @click="onCloseProject">退出系统</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onBeforeUnmount, onMounted, watch} from "vue";
import {loginBill} from "./Login";
import {UserOutlined, LockOutlined, SecurityScanOutlined} from '@ant-design/icons-vue';
import {Form, Button, Input} from 'ant-design-vue';
import QrcodeVue from 'qrcode.vue'
export default defineComponent({
  name: "Login",
  components: {
    UserOutlined,
    LockOutlined,
    SecurityScanOutlined,
    AForm: Form,
    AFormItem: Form.Item,
    AButton: Button,
    AInput: Input,
    QrcodeVue,
  },
  setup() {
    let {
      refData,
      ELoginType,
      init,
      onLoginMethodChange,
      getDDQrCode,
      reloadTip,
      onFinish,
      onFinishFailed,
      queryLoginCode,
      clearTimeoutFun,
      onCloseProject,
    } = loginBill();


    onMounted(() => {
      // init()
      // reloadTip();
      // getDDQrCode(); //钉钉二维码
      queryLoginCode()
    })
    onBeforeUnmount(() => {
      clearTimeoutFun()
    })


    return {
      ...refData,
      ELoginType,
      onLoginMethodChange,
      onFinish,
      onFinishFailed,
      queryLoginCode,
      clearTimeoutFun,
      onCloseProject,
    }


  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
</script>

<style lang="less" scoped>
.login {
  height: 100%;
  background: url("~@/assets/image/authority/auth_background.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: flex-end;
  //position: fixed;
  //bottom: 0;
  .login__fix {
    display: flex;

    .login__boy {
      width: 45.3%;
      z-index: 2;

      img {
        width: 100%;
      }
    }

    .login__box {
      width: 62%;
      margin-left: -11.5%;
      display: flex;
      align-items: center;
      z-index: 1;
      position: relative;

      img[alt="box"] {
        width: 100%;
      }

      img[alt="logo"] {
        width: 38.2%;
      }

      .login__scan {
        width: 100%;
        position: absolute;
        text-align: center;
        padding: 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        @media (min-width: 1367px) and (max-width: 1920px) {
          height: 30rem;
        }
        @media (max-width: 1366px) {
          height: 35rem;
        }

        .login__radio {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 23px;

          div {
            font-size: 26px;
            font-weight: bold;
            line-height: 58px;
            color: #41464A;
            width: 178px;
            height: 58px;
            cursor: pointer;
            box-sizing: content-box;
            transition: all .2s;
            user-select: none;

            &.active {
              color: #FFF;
              background-color: #F38228;
            }
          }

          & > :nth-child(1) {
            border: 1px solid #FFF;
            border-radius: 4px 0 0 4px;
          }

          & > :nth-child(2) {
            border-top: 1px solid #FFF;
            border-bottom: 1px solid #FFF;
          }

          & > :nth-child(3) {
            border: 1px solid #FFF;
            border-radius: 0 4px 4px 0;
          }
        }

        .login__input {
          .title{
            height: 24px;
            color: #0082ef;
            font-size: 20px;
            text-align: center;
          }
          .login__qrcode{
            background-color: #FFFFFF;
            width: 300px;
            height: 300px;
            border-radius: 5px;
            position: relative;
            .login_qrcode_content{
              text-align: center;
              margin-top: 20px;
            }
            .login_qrcode_text{
              margin-top: 20px;
              text-align: center;
              color: #898d90;
              font-size: 14px;
            }
            .login_qrcode_refresh{
              position: absolute;
              top: 34px;
              left: 50%;
              width: 210px;
              height: 210px;
              background: rgba(255,255,255,0.9);
              z-index: 100;
              text-align: center;
              padding-top: 90px;
              color: #fa5b5b;
              font-size: 14px;
              margin-left: -105px;
            }
          }
          #login__qrcode,.login__qrcode{
            margin-top: 30px;
          }
        }
      }

    }
  }
}
</style>
